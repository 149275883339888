<template>
  <v-card>
    <TopKeystoneToolbar
      entity-type="metaholding"
      v-model="search"
      :open-new="openNewMetaHolding"
      v-on:refresh="refresh"
    >
    </TopKeystoneToolbar>

    <MetaHoldingForm
      v-model="dialogForm"
      :is-edit-form.sync="isEditForm"
      :entity-id="currentMetaHoldingId"
      v-on:refresh="refresh"
      :default-entity="defaultEntity"
      ref="metaHoldingForm"
    >
    </MetaHoldingForm>

    <div class="entity-view-div">
      <v-data-table
        :headers-length="4"
        :loading="$store.getters.getLoadingMetaHoldings"
        :items="metaHoldings"
        :headers="headers"
        :options.sync="pagination"
        :server-items-length="totalItems"
        @update:options="callApi"
        :footer-props="{
          itemsPerPageOptions: rowPerPageItem
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              {{ props.item.id }}
            </td>
            <td>
              <strong>
                {{ props.item.name }}
              </strong>
            </td>
            <td>
              <v-icon small>
                launch
              </v-icon>
              <a @click="goToHolding(props.item)"> Holdings of {{props.item.name}}</a>
            </td>
            <td>
              <v-btn icon>
                <v-icon @click="openEditMetaHolding(props.item.id)">
                  edit
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import store from '../../../store'
import { keystoneV2 } from '../../../store/modules/keystoneV2'
import * as keystoneV2Utils from '../../../utils/keystoneV2Utils'
import TopKeystoneToolbar from '@/components/KeystoneV2/TopKeystoneToolbar'
import { rulesMixin } from '@/mixins/rulesMixin'
import MetaHoldingForm from '@/components/KeystoneV2/Form/MetaHoldingForm'
import MetaHoldingModel from '@/models/Keystone_v2/MetaHoldingModel'
import { ROW_PER_PAGE_ITEM } from '@/components/KeystoneV2/commonConstantKeystone'
import { keystoneTabViewMixin } from '@/mixins/keystoneTabViewMixin'
import { getEntityApiPayload } from '@/components/KeystoneV2/payloadHelperKeystone'
import _ from 'lodash'

// load the store module dynamically only when needed
if (!store.state.keystoneV2) store.registerModule('keystoneV2', keystoneV2)

export default {
  name: 'MetaHoldingView.vue',
  components: { MetaHoldingForm, TopKeystoneToolbar },
  mixins: [rulesMixin, keystoneTabViewMixin],
  data: function () {
    return {
      headers: [
        { text: 'Internal ID', value: 'id', width: '15px', sortable: false },
        { text: 'Metaholding name', value: 'name' },
        { text: 'Holdings', sortable: false },
        { text: 'Action', sortable: false }
      ],
      isEditForm: false,
      processingMetaHolding: false,
      search: '',
      dialogForm: false,
      currentMetaHoldingId: null,
      defaultEntity: null,
      rowPerPageItem: ROW_PER_PAGE_ITEM,
      entityType: 'metaholding',
      pagination: {}
    }
  },
  async mounted () {
    await this.callApiIfRightTab()
    if (this.$store.getters.getEditBilling && this.$store.getters.getEditBillingID !== 0 && this.$route.query.type === 'metaholding') {
      this.openEditMetaHolding(this.$store.getters.getEditBillingID)
      this.$store.commit('resetEditBilling')
    }
  },
  methods: {
    async callApi () {
      const payload = getEntityApiPayload(this.search, this.pagination, 'metaholdingId')
      await this.$store.dispatch('getApiMetaHoldings', payload)
    },
    async refresh () {
      // for avoid spam
      if (this.$store.getters.getLoadingMetaHoldings) {
        return
      }
      await this.callApi()
    },
    goToHolding (metaHolding) {
      const option = {
        text: metaHolding.name,
        id: metaHolding.id,
        isParent: true,
        source: 'metaholding'
      }
      this.$store.commit('setTabSearchHoldings', option)
      const tab = keystoneV2Utils.getTabForEntity('holding')
      this.$store.commit('setCurrentTab', tab)
    },
    openNewMetaHolding () {
      this.isEditForm = false
      this.defaultEntity = new MetaHoldingModel()
      this.$refs.metaHoldingForm.defineMsaTypeItemsForNewForm()
      this.currentMetaHoldingId = null
      this.dialogForm = true
    },
    async openEditMetaHolding (metaHoldingId) {
      this.isEditForm = true
      this.dialogForm = true
      this.currentMetaHoldingId = metaHoldingId
    },
    /**
     * called by open form mixin
     */
    openNew () {
      this.openNewMetaHolding()
    },
    /**
     * called by open form mixin
     * @param metaHoldingId
     */
    openEdit (metaHoldingId) {
      this.openEditMetaHolding(metaHoldingId)
    }
  },
  computed: {
    metaHoldings: function () {
      return this.$store.getters.getMetaHoldings
    },
    totalItems: function () {
      return this.$store.getters.getTotalCountMetaHoldings
    }
  },
  watch: {
    '$store.getters.getTabSearchMetaHoldings': {
      immediate: false,
      deep: true,
      handler: async function (newValue) {
        this.pagination.page = 1
        await this.callApi()
      }
    },
    search: _.debounce(function () {
      this.callApi()
    }, 600)
  }
}
</script>

<style scoped>

</style>
